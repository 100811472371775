import { RiskClassEnum } from "@/apis/services/HazardService";

export const AGGREGATE = "aggregate";

export const ALL_HAZARDS = "all_hazards";
export const ALL_CONSEQUENCES = "all_consequences";

export const RISK_CLASS_TEST_LOOKUP: any = {
  [RiskClassEnum.Value0]: "zero",
  [RiskClassEnum.Value1]: "one",
  [RiskClassEnum.Value2]: "two",
  [RiskClassEnum.Value3]: "three",
  [RiskClassEnum.Value4]: "four",
};
